<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title>
        <span class="font-weight-bold">
          {{ $route.name }}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">
          <v-icon>mdi-plus</v-icon>
          {{ $t("add") }} {{ meta.single }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-debounce:500ms="getItems"
              class="mr-1"
              v-model="filter.Name"
              :label="$t('bhth')"
              hide-details=""
              prepend-inner-icon="search"
              dense=""
              outlined=""
              filled=""
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              :items="$global.state.fieldsTypes"
              v-model="filter.FieldTypeId"
              :placeholder="$t('alnwa')"
              item-text="name"
              item-value="id"
              dense=""
              outlined=""
              filled=""
              hide-details="auto"
              @change="getItems"
            />
          </v-col>
        </v-row>
        <v-data-table
          class="mytable"
          :items-per-page="$global.state.filter.pageSize"
          :items="$global.state.fields"
          :loading="$global.state.loading"
          :headers="headers"
          hide-default-footer
          :loading-text="$t('loading')"
          :no-data-text="$t('no-data')"
          :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
        >
          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                  <v-icon color="error"> delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('hthf') }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="openEditDialog(item)" v-on="on" icon>
                  <v-icon color="info"> edit </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('edit') }}</span>
            </v-tooltip>

            <v-tooltip
              bottom=""
              v-if="
                item.fieldType.name == 'Multiple select' ||
                item.fieldType.name == 'Table' ||
                item.fieldType.name == 'Single select'
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn @click="openSubFieldsDialog(item)" v-on="on" icon>
                  <v-icon color="blue"> view_list </v-icon>
                </v-btn>
              </template>
              <span> {{ $t('alhqwl') }} </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Pagination />
    <Dialog />
    <SubFieldsDialog />
  </div>
</template>

<script>
export default {
  components: {
    SubFieldsDialog: () => import("./subFieldsDialog.vue"),
    Dialog: () => import("./dialog.vue"),
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      filter: {
        Name: "",
        FieldTypeId: "",
      },
      headers: [
        {
          text: this.$t("alism"),
          value: "name",
        },
        {
          text: this.$t('anwan-alhql'),
          value: "title",
        },
        { text: this.$t('alnwa'), value: "fieldType.name" },
        {
          text: this.$t('altwl'),
          value: "length",
        },
        {
          text: this.$t('aala-qymh'),
          value: "max",
        },
        {
          text: this.$t('aql-qymh'),
          value: "min",
        },
        {
          text: this.$t('rsalh-alkhta'),
          value: "errorMessage",
        },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    getItems() {
      this.$global.dispatch(`getFieldType`);
      this.$global.dispatch(`get${this.meta.endPoint}`, this.filter);
    },
    deleteItem(id, i) {
      this.$service.deleteItem(id, `${this.$route.meta.endPoint}`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
        }, 10);
      });
    },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setFieldDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setFieldDialog");
      this.$eventBus.$emit("fill-fields");
    },

    openSubFieldsDialog(item) {
      item.dialogType = "add";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setSubFieldsDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  mounted() {
    this.getItems();
    console.log("fields mounted");
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },
};
</script>
